import React from 'react';
import './styles/index.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Home from './pages/Home';
import Bill from './pages/Bill';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import { sharedStyles} from './components/sharedStyles';
import OAuthCallback from './pages/OAuthCallback';

function Logout() {
  localStorage.clear()
  return <Navigate to="/login" />
}

function RegisterAndLogout() {
  localStorage.clear()
  return <Register />
}


function App() {
  return (
    <BrowserRouter>
    <div className={sharedStyles.mainContainerClasses}>
      <Routes>
        <Route
          path="/" 
          element = {
            // <ProtectedRoute>
              <Home />
            // {/* </ProtectedRoute> */}
          }
        />
        <Route path="/bill/:billId" 
          element= {
            // <ProtectedRoute>
              <Bill />
            // </ProtectedRoute>
          } 
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<RegisterAndLogout />}/>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/oauth/callback" element={<OAuthCallback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
