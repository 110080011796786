import React, { useRef, useEffect } from 'react';
import { sharedStyles } from './sharedStyles';
import { CitationIcon } from './CitationIcon';

export function ConversationArea({ messages, onCitationClick }) {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const renderMessage = (message) => {
        if (message.type === 'assistant' && message.citations) {
            const citationMap = {};
            message.citations.forEach((citation) => {
                citationMap[citation.number] = citation;
            });

            const parts = message.content.split(/(\[\d+\])/); // Split content by citation numbers
            return (
                <div>
                    {parts.map((part, index) => {
                        const match = part.match(/\[(\d+)\]/); // Check if part is a citation number
                        if (match) {
                            const citationNumber = match[1];
                            const citation = citationMap[citationNumber];
                            return (
                                <span
                                    key={index}
                                    className="relative"
                                    onClick={() => onCitationClick(citation)}
                                >
                                    <CitationIcon />
                                </span>
                            );
                        }
                        return <span key={index}>{part}</span>;
                    })}
                </div>
            );
        }

        return <p>{message.content}</p>;
    };

    return (
        <div className="w-full flex-1 overflow-y-auto">
            {messages.map((message, index) => (
                <div key={index}
                     className={`${sharedStyles.messageClasses} ${message.type === 'user' ? sharedStyles.userInputClasses : 'py-2'}`}
                     style={message.type === 'user' ? sharedStyles.userInputStyles : {}}>
                    <div className={`text-sm ${message.type === 'user' ? 'text-white' : 'text-white/80'} text-left w-full`}>
                        {renderMessage(message)}
                    </div>
                </div>
            ))}
            <div ref={messagesEndRef} />
        </div>
    );
}

