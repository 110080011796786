import React from 'react';

export function CitationIcon({ onClick }) {
  return (
    <div
      className="inline-flex justify-center items-center rounded-full cursor-pointer transition-all duration-200 ease-in-out"
      style={{
        width: '16px',
        height: '16px',
        background: 'linear-gradient(105.45deg, rgba(255, 255, 255, 0.2) -34.17%, rgba(255, 255, 255, 0.05) 126.25%)',
        verticalAlign: 'middle',
        marginLeft: '6px',
        marginRight: '4px',
        boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.1)',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.background = 'linear-gradient(105.45deg, rgba(255, 255, 255, 0.3) -34.17%, rgba(255, 255, 255, 0.1) 126.25%)';
        e.currentTarget.style.boxShadow = '0 0 5px 2px rgba(255, 255, 255, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = 'linear-gradient(105.45deg, rgba(255, 255, 255, 0.2) -34.17%, rgba(255, 255, 255, 0.05) 126.25%)';
        e.currentTarget.style.boxShadow = '0 0 0 0 rgba(255, 255, 255, 0.1)';
      }}
      onClick={onClick}
    >
      <span className="text-grey-900 text-[10px]">&lt;&gt;</span>
    </div>
  );
}
