import api from "../api";

const RAGChat = {
  async sendMessage(billId, message) {
    console.log("Sending message for billId:", billId);
    if (!billId) {
        console.error("Bill ID is null or undefined in sendMessage");
        throw new Error("Bill ID is required");
    }

    try {
        const response = await api.post(`/api/rag/${billId}/`, 
            { question: message },
            { withCredentials: true }
        );
        
        if (response.data.chat_session_id) {
            localStorage.setItem(`chatSessionId_${billId}`, response.data.chat_session_id);
        }

        return response.data; // Ensure the data structure is { answer: { answer, citations }, chat_session_id }
    } catch (error) {
        console.error('Error sending message:', error);
        throw error;
    }
},

  async fetchChatHistory(billId) {
    console.log("Fetching chat history for billId:", billId);
    if (!billId) {
      console.error("Bill ID is null or undefined in fetchChatHistory");
      throw new Error("Bill ID is required");
    }

    const chatSessionId = localStorage.getItem(`chatSessionId_${billId}`);
    if (!chatSessionId) return [];

    try {
      const response = await api.get(`/api/rag/${billId}/`, {
        // params: { chat_session_id: chatSessionId },
        withCredentials: true
      });
      
      return response.data.history.map(chat => ({
        type: chat.is_user ? 'user' : 'assistant',
        content: chat.message
      }));
    } catch (error) {
      console.error('Error fetching chat history:', error);
      if (error.response && error.response.status === 404) {
        localStorage.removeItem(`chatSessionId_${billId}`);
      }
      return [];
    }
  },

  async startNewChat(billId) {
    console.log("Starting new chat for billId:", billId);
    if (!billId) {
      console.error("Bill ID is null or undefined in startNewChat");
      throw new Error("Bill ID is required");
    }

    try {
      const response = await api.post(`/api/rag/${billId}/`, 
        { question: "Hello, I'd like to start a new chat about this bill." },
        { withCredentials: true }
      );
      localStorage.setItem(`chatSessionId_${billId}`, response.data.chat_session_id);
      return await this.fetchChatHistory(billId);
    } catch (error) {
      console.error('Error starting new chat:', error);
      throw error;
    }
  }
};

export default RAGChat;