import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { LogOut } from 'lucide-react';
import GoogleLoginButton from './GoogleLoginButton';

const Hero = ({ title, subtitle, onLogout }) => {
  const { user } = useContext(UserContext);

  return (
    <div className="flex items-center justify-between p-4 mb-8">
      {/* Empty div for balance */}
      <div className="w-48"></div>

      {/* Title and Subtitle */}
      <div className="text-center flex-grow">
        <h1 className="text-4xl font-bold mb-2">{title}</h1>
        <p className="text-xl text-gray-400">{subtitle}</p>
      </div>

      {/* User Profile or Login Button */}
      <div className="flex items-center w-48 justify-end">
        {user ? (
          <div className="flex items-center">

            <div
              className="w-10 h-10 rounded-full bg-red-500 flex items-center justify-center text-white font-bold mr-2"
              role="img"
              aria-label="User profile"
            >
              {user.first_name ? user.first_name[0] : 'U'}
            </div>
            <button onClick={onLogout} className="text-white">
              <LogOut size={16} />
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center">
          {/* Google Login Button */}
          <GoogleLoginButton />

          {/* Disclaimer below the Google button */}
          <p className="text-sm text-gray-400 mt-2">
            Log in to chat with bills!
          </p>
        </div>
        )}
      </div>
    </div>
  );
};

export default Hero;