import { useState, useEffect, useCallback, useContext } from "react"
import api from "../api"
import BillCard from "../components/BillCard"
import PageContainer from "../components/PageContainer";
import NavigationSidebar from "../components/NavigationSideBar";
import Hero from "../components/Hero";
import SearchBar from "../components/SearchBar";
import TagsMenu from "../components/TagsMenu";
import { ArrowUpDown, X, ChevronRight, ChevronLeft } from 'lucide-react';
import { UserContext } from '../contexts/UserContext';


function Home () {
    const [bills, setBills] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [searchQuery, setSearchQuery] = useState('')
    const [selectedTags, setSelectedTags] = useState([]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);  // Manage the current page
    const [totalPages, setTotalPages] = useState(1);    // Total pages returned by the API
    
    const { setUser } = useContext(UserContext);

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      setUser(null);
    };

    const Title = "Lawgiver"
    const Subtitle = "A legislative oracle."

    const getBills = useCallback(() => {
      let url = `/api/bills/?page=${currentPage}`;  // Include the page number in the URL
        const params = new URLSearchParams();
        
        if (searchQuery) params.append('search', searchQuery);
        if (selectedTags.length > 0) params.append('tags', selectedTags.join(','));
        
        if (params.toString()) url += `&${params.toString()}`;

        api.get(url)
        .then((res) => res.data)
        .then((data) => { 
          setBills(Array.isArray(data.results) ? data.results : []);  // Ensure the response is an array
          setTotalPages(Math.ceil(data.count / 10)); 
            console.log(data);
         })
        .catch((err) => alert(err)) 
    }, [searchQuery, selectedTags, currentPage]);

    // Load all bills when the component mounts
    useEffect(() => {
        getBills();
    }, [getBills]);

    const toggleSortOrder = () => {
      setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    }

    const sortedBills = [...bills].sort((a, b) => {
      const dateA = new Date(a.date_published);
      const dateB = new Date(b.date_published);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    const handleTagSelect = (tag) => {
        setSelectedTags(prev => 
            prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
        );
        getBills();  // Fetch bills when tags change
    };

    const removeTag = (tag) => {
        setSelectedTags(prev => prev.filter(t => t !== tag));
        getBills();  // Fetch bills when tags change
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchEnter = () => {
          setSearchQuery(searchTerm);
          setCurrentPage(1);
          getBills();
      };

    const handleNextPage = () => {
      if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
      <PageContainer>
      <NavigationSidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Hero title={Title} subtitle={Subtitle} onLogout={handleLogout} />

        <div className="flex-1 flex overflow-hidden">
          <main className="flex-1 overflow-y-auto px-8 pt-6">
            <SearchBar 
              placeholder="Search legislation (press Enter to search)"
              value={searchTerm}
              onChange={handleSearchChange}
              onEnterPress={handleSearchEnter}
            />

            {selectedTags.length > 0 && (
              <div className="mt-4 flex flex-wrap gap-2">
                {selectedTags.map(tag => (
                  <span key={tag} className="px-2 py-1 bg-blue-500 text-white rounded-full text-sm flex items-center">
                    {tag}
                    <button onClick={() => removeTag(tag)} className="ml-2">
                      <X size={14} />
                    </button>
                  </span>
                ))}
              </div>
            )}

            <div className="mt-8 mb-2 flex items-center justify-between">
              <h2 className="text-2xl font-semibold">Recently Updated Bills</h2>
              <button 
                className="text-sm text-gray-400 hover:text-white transition-colors"
                onClick={toggleSortOrder}
                title={sortOrder === 'desc' ? "Sort oldest first" : "Sort newest first"}
              >
                <ArrowUpDown size={16} />
              </button>
            </div>

            <div className="border-b border-gray-700 mb-4"></div>

            <div className="space-y-4">
              {sortedBills.map((bill) => (
                <BillCard bill={bill} key={bill.id} />
              ))}
            </div>
            {/* Pagination Controls */}
            <div className="mt-8 flex justify-center items-center space-x-4">
                <button 
                    onClick={handlePrevPage} 
                    disabled={currentPage === 1} 
                    className={`p-2 rounded-full ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-700'} transition`}
                >
                    <ChevronLeft size={20} />
                </button>
                <span className="text-sm text-gray-400">
                    Page {currentPage} of {totalPages}
                </span>
                <button 
                    onClick={handleNextPage} 
                    disabled={currentPage === totalPages} 
                    className={`p-2 rounded-full ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-700'} transition`}
                >
                    <ChevronRight size={20} />
                </button>
            </div>
          </main>
        </div>
      </div>
      <TagsMenu selectedTags={selectedTags} onTagSelect={handleTagSelect} />
    </PageContainer>
);
}
export default Home;