import React from 'react';
import { Mail } from 'lucide-react'; // Importing the mail icon from Lucide

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-6">
        <strong>Effective Date:</strong> 10/01/2024
      </p>

      <p className="text-gray-800 mb-4">
        <strong>Physinomos, Inc.</strong> ("we", "our", or "us") operates 
        <strong> Lawgiver.ai</strong> (the "App"). This Privacy Policy explains how we collect, use, and share information about you when you use our App. By using the App, you agree to the collection and use of information in accordance with this Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">1. Information We Collect</h2>
      <p className="text-gray-800 mb-2">
        When you use the App, we collect the following types of information through Google OAuth:
      </p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li>
          <strong>Personal Information:</strong> When you authenticate using Google OAuth, we collect the basic profile information that you permit us to access through your Google account. This may include your name, email address, and profile picture.
        </li>
        <li>
          <strong>Usage Data:</strong> We collect information on how you use the App, including but not limited to access times, features used, and interactions within the App.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">2. How We Use Your Information</h2>
      <p className="text-gray-800 mb-2">We use the information we collect to:</p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li>Provide, maintain, and improve the App's functionality.</li>
        <li>Authenticate your identity and grant access to certain features.</li>
        <li>Send you updates, notifications, and relevant information about the App.</li>
        <li>Ensure compliance with legal requirements and protect the security of our App.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">3. Data Sharing and Disclosure</h2>
      <p className="text-gray-800 mb-2">
        We will not share your personal data with third parties, except in the following circumstances:
      </p>
      <ul className="list-disc pl-5 text-gray-800 mb-6">
        <li>
          <strong>Service Providers:</strong> We may share your information with third-party service providers that help us operate our App, such as cloud hosting providers. These parties are bound by strict confidentiality obligations and are prohibited from using your data for any purposes other than providing services to us.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., court orders, government regulations).
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">4. Data Retention</h2>
      <p className="text-gray-800 mb-6">
        We retain your data for as long as it is necessary to fulfill the purposes described in this policy, or as required by applicable law. If you request deletion of your data, we will remove it in accordance with the terms outlined below.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">5. Data Deletion and Your Choices</h2>
      <p className="text-gray-800 mb-6">
        You have the right to request the deletion of your personal information. If you would like to request deletion, you can do so by contacting us at <a href="mailto:alex@lawgiver.ai" className="text-blue-500 hover:underline">alex@lawgiver.ai</a>. Upon receiving your request, we will delete your information from our systems, except where retention is required by law.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">6. Security of Your Information</h2>
      <p className="text-gray-800 mb-6">
        We take the security of your information seriously and have implemented industry-standard measures to protect your data from unauthorized access, disclosure, alteration, or destruction. This includes encryption and access control mechanisms to safeguard personal information.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">7. Compliance with Privacy Laws</h2>
      <p className="text-gray-800 mb-6">
        We comply with applicable privacy laws, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA). If you are a resident of the European Union, you may have additional rights regarding your personal data under GDPR, such as the right to access, correct, or delete your information.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">8. Changes to this Privacy Policy</h2>
      <p className="text-gray-800 mb-6">
        We may update this Privacy Policy from time to time. When we make significant changes, we will notify you by email or by posting a notice in the App. Please review the Privacy Policy periodically for any updates.
      </p>

      <h2 className="text-2xl font-semibold text-gray-900 mb-2">9. Contact Us</h2>
      <p className="text-gray-800">
        If you have any questions about this Privacy Policy or your data, please contact us at:
      </p>
      <div className="flex items-center mt-4">
        <Mail className="w-5 h-5 mr-2 text-gray-600" />
        <p className="text-gray-800">
          <strong>Physinomos, Inc.</strong> <br />
          <a href="mailto:alex@lawgiver.ai" className="text-blue-500 hover:underline">alex@lawgiver.ai</a> <br />
          1606 Headway Cir., STE 9456 <br />
          Austin, TX, 78754 US
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
