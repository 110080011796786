import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import api from '../api';

const PDFViewer = React.forwardRef(({ billId, onError }, ref) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [error, setError] = useState(null);

    // Move plugin initialization to the top level
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const searchPluginInstance = searchPlugin();
   
    const { highlight, jumpToMatch, clearHighlights } = searchPluginInstance;

    const previousCitationRef = useRef(null);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await api.get(`/api/bills/pdf/${billId}`, {
                    responseType: 'blob',
                });
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                setPdfUrl(url);
            } catch (err) {
                setError(err.message);
                onError(err); // Notify parent component about the error
            }
        };

        if (billId) {
            fetchPdf();
        }
    }, [billId, onError]);

    const getFirstThreeWords = (quote) => {
      return quote.split(/\s+/).slice(0, 3).join(' ');
    };

    useImperativeHandle(ref, () => ({
        highlightCitation: (citation) => {
            if (citation && citation !== previousCitationRef.current) {
                const { quote } = citation;
                const firstThreeWords = getFirstThreeWords(quote);
                console.log('Using first three words to highlight:', firstThreeWords);

                // Clear previous highlights
                clearHighlights();
                console.log('Cleared previous highlights');

                // Highlight the new quote
                highlight([firstThreeWords]).then((results) => {
                    console.log('Highlighting complete, results:', results);
                    if (results.length > 0) {
                        // Jump to the first match of the quote
                        jumpToMatch(0);
                        console.log('Jumped to first match');
                    } else {
                        console.warn('No matches found for the quote');
                    }
                }).catch((err) => {
                    console.error('Error highlighting quote:', err);
                });

                previousCitationRef.current = citation;
            }
        }
    }));


    return (
        <div style={{ height: '100%' }}>
            {error ? (
                <div>Error: {error}</div>
            ) : pdfUrl ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={pdfUrl}
                        plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
                    />
                </Worker>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
});

export default React.memo(PDFViewer);