import React, { useRef, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as LinkIcon } from 'lucide-react';
import { ChevronDown, ChevronUp, Download } from 'lucide-react';
import api from '../api';
import { sharedStyles } from './sharedStyles';

export function SummarySection({ isExpanded, onToggle, billId }) {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(isExpanded ? 'auto' : 0);
  const [billData, setBillData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFullSummary, setShowFullSummary] = useState(false);

  useEffect(() => {
    const fetchBillSummary = async () => {
      if (!billId) {
        setError('No bill ID provided');
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const response = await api.get(`/api/bills/summary/${billId}/`, {
          withCredentials: true,
        });
        setBillData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching bill summary:', err);
        setError('Failed to fetch bill summary');
        setLoading(false);
      }
    };

    fetchBillSummary();
  }, [billId]);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current && isExpanded) {
        setContentHeight(contentRef.current.scrollHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [isExpanded, billData]);

  const handleDownload = async () => {
    try {
      const response = await api.get(`/api/bills/pdf/${billId}/`, {
        responseType: 'blob', // Important for handling file downloads
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // Use billData.title or a default value if summary or title is not available
      link.download = `${billData.title || 'bill'}.pdf`;
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
        if (error.response && error.response.status === 404) {
          alert('This bill text is currently unavailable.');
        } else {
          console.error('Error downloading the bill:', error);
          alert('Failed to download the bill. Please try again.');
        }
    }
  };

  const toggleSummary = () => {
    setShowFullSummary(!showFullSummary);
  };

  if (loading) return <div className="text-white">Loading...</div>;
  if (error) return <div className="text-white">{error}</div>;
  if (!billData) return <div className="text-white">No data available</div>;

  const { summary, sponsors } = billData;

  // Get full names of all sponsors
  const sponsorNames = Object.keys(sponsors).length > 0
    ? Object.values(sponsors).map(sponsor => sponsor.fullName).join(', ')
    : 'No sponsors listed';

  const summaryText = summary?.main_purpose || 'Summary will appear when PDF becomes available.';
  // Truncate the summary to 200 characters
  const truncatedSummary = summaryText.length > 100 
    ? summaryText.slice(0, 100) + '...'
    : summaryText;

  return (
    <div
      className={`w-full ${sharedStyles.summarySectionClasses}`}
      style={sharedStyles.summarySectionStyles}
    >
      <div style={sharedStyles.strokeStyles} />
      <div className="relative z-10">
        <div className="flex justify-between items-center">
          <div>

            <RouterLink to={`/bill/${billData.id}`} className="group">
              <h3 className="text-1xl font-bold text-white">
              {billData.bill_type?.toUpperCase() || "UNKNOWN TYPE"} {billData.bill_no || summary?.bill_number || 'Unknown Bill'}
              </h3>
              <h3 className="text-md text-gray-300 flex items-center">
                {billData.title || summary?.title || 'No Title'}
                &nbsp;
                <div className="p-1 rounded-full hover:bg-white/10">
                  <LinkIcon size={12} className="text-white" />
                </div>
              </h3>
            </RouterLink>
            {!isExpanded && (
              <p className="text-sm text-white/60">
                {billData.latest_action_date
                  ? new Date(billData.latest_action_date).toLocaleDateString()
                  : 'N/A'}
              </p>
            )}
          </div>
          <div className="flex gap-1">
            <button
              className="p-1 rounded-full hover:bg-white/10"
              onClick={handleDownload}
              title="Download this bill"
            >
              <Download size={12} className="text-white" />
            </button>
            <button
              className="p-1 rounded-full hover:bg-white/10"
              title="Toggle expand"
              onClick={onToggle}
            >
              {isExpanded ? (
                <ChevronUp size={16} className="text-white" />
              ) : (
                <ChevronDown size={16} className="text-white" />
              )}
            </button>
          </div>
        </div>
        <div
          ref={contentRef}
          className={`mt-1 overflow-y-auto transition-all duration-300 ease-in-out ${
            isExpanded ? 'opacity-100' : 'opacity-0 max-h-0'
          }`}
          style={{ maxHeight: isExpanded ? `${contentHeight}px` : '0px' }}
        >
          <div className="space-y-0">
            <KeyValuePair
              label="Latest Action Date"
              value={
                billData.latest_action_date
                  ? new Date(billData.latest_action_date).toLocaleDateString()
                  : 'N/A'
              }
            />
            <KeyValuePair
              label="Congress"
              value={billData.congress || 'N/A'}
            />
            <KeyValuePair
              label="Primary Sponsor"
              value={sponsorNames || 'N/A'}
            />
          </div>
          <div className="mt-2 text-sm text-white/80">
            <p>
                {showFullSummary ? summaryText : truncatedSummary}
              </p>
              {summaryText.length > 100 && (
                <button
                  onClick={toggleSummary}
                  className="text-blue-400 hover:text-blue-300 mt-1"
                >
                  {showFullSummary ? 'Show Less' : 'Show More'}
                </button>
              )}
          </div>
          {summary && (
            <>
              <h4 className="mt-4 text-lg font-semibold text-white">
                Key Points
              </h4>
              <ul className="list-disc list-inside text-sm text-white/80">
                <li>Organizations: {summary.organizations || 'None'}</li>
                <li>Officials: {summary.officials || 'None'}</li>
                <li>
                  Referenced Legislation:{' '}
                  {summary.referenced_legislation || 'None'}
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function KeyValuePair({ label, value }) {
  return (
    <div className="flex justify-between border-b border-white/20 py-1">
      <span className="text-sm text-white/60">{label}</span>
      <span className="text-sm text-white">{value}</span>
    </div>
  );
}