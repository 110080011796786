import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Home, FileText } from 'lucide-react';
import api from "../api";  // Adjust this import path as needed
import { sharedStyles } from '../components/sharedStyles';

const NavigationSidebar = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [chatSessions, setChatSessions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fetchChatSessions();
  }, []);

  const fetchChatSessions = () => {
    api.get('/api/user-chat-sessions/')
      .then((res) => res.data)
      .then((data) => {
        setChatSessions(data);
      })
      .catch((err) => console.error('Error fetching chat sessions:', err));
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className={`${isExpanded ? 'w-64' : 'w-16'} flex flex-col`} style={sharedStyles.navigationSidebarStyles}>
      <div style={sharedStyles.strokeStyles} />
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-4 bg-[#1e2738] p-1 rounded-full z-10"
      >
        {isExpanded ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
      </button>

      <nav className="p-4 flex-1 overflow-hidden flex flex-col">
        <Link to="/" className={`flex items-center mb-6 ${isActive('/') ? 'text-blue-400' : ''}`}>
          <Home size={24} />
          {isExpanded && <span className="ml-2">Home</span>}
        </Link>

        {isExpanded && (
          <>
            <h3 className="mb-2 text-sm font-semibold">Recent Conversations</h3>
            <ul className="overflow-y-auto flex-1 space-y-1">
              {chatSessions.map((session) => (
                <li key={session.chat_session_id} className="mb-2">
                  <Link 
                    to={`/bill/${session.bill_id}`} 
                    className={`flex items-center py-1 px-1 hover:text-gray-300 ${isActive(`/bills/${session.bill_id}`) ? 'text-blue-400' : ''}`}
                  >
                    <div className="flex-shrink-0 w-6 h-6 mr-3 flex items-center justify-center">
                      <FileText size={16} />
                    </div>
                    <span className="text-base overflow-hidden text-ellipsis whitespace-nowrap">{session.bill_title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </nav>
    </div>
  );
};

export default NavigationSidebar;