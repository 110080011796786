import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const OAuthCallback = () => {
  const { setUser } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleOAuthCallback = () => {
      const params = new URLSearchParams(location.search);
      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');
      const firstName = params.get('first_name');
      const lastName = params.get('last_name');
      const email = params.get('email');
      const profilePicture = params.get('profile_picture');

      if (accessToken && refreshToken && email) {
        // Store tokens in localStorage (or cookies)
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        // Set user in context
        setUser({
          first_name: firstName,
          last_name: lastName,
          email: email,
          profilePicture: profilePicture,
        });

        // Redirect to the homepage or dashboard
        navigate('/');
      } else {
        console.error('OAuth callback failed: no access token received');
        navigate('/login'); // Redirect to login page if tokens are not available
      }
    };

    handleOAuthCallback();
  }, [location.search, setUser, navigate]);

  return <div>Loading...</div>;
};

export default OAuthCallback;