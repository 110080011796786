// src/sharedStyles.js

import { min } from "date-fns";

export const sharedStyles = {
  
  containerClasses: "relative flex flex-col items-center p-8 pb-4 gap-8 rounded-md overflow-hidden noise-bg",

  containerStyles: {
    background: "linear-gradient(108.74deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 50%)",
    boxShadow: "0px 0px 50px -25px rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(50px)",
  },

  strokeStyles: {
    position: 'absolute',
    inset: 0,
    borderRadius: '6px',
    pointerEvents: 'none',
    border: '0.5px solid rgba(196, 213, 232, 0.22)',
    // borderImage: 'linear-gradient(108.74deg, rgba(196, 213, 232, 0.28) 0%, rgba(255, 255, 255, 0.54) 25%, rgba(255, 255, 255, 0.3) 50%, rgba(234, 224, 190, 0.24) 100%) 1',
  },

  summarySectionClasses: "relative rounded-md overflow-hidden",

  summarySectionStyles: {
    padding: '16px 32px',
    background: "linear-gradient(108.74deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.01) 100%)",
  },

  messageClasses: "mb-2 text-sm px-8",

  userInputClasses: "flex flex-row items-center w-full rounded-md",

  userInputStyles: {
    background: "linear-gradient(108.74deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%)",
    padding: '8px 32px',
    minHeight: '48px',
  },

  miniSearchStyles: {
    background: "linear-gradient(108.74deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%)",
    padding: '8px 20px',
    minHeight: '20px',
  },

  inputStyles: {
    background: 'transparent',
    color: 'white',
    '::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },

    // New styles for TagsMenu
    tagsMenuStyles: {
      background: "linear-gradient(108.74deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.01) 100%)",
      borderRadius: '8px',
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    },
  
    tagsMenuInputStyles: {
      background: "#151c2c",
      color: 'white',
      '::placeholder': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
  
    tagStyles: {
      backgroundColor: "#151c2c",
      color: "#c4d5e8",
      borderRadius: '9999px',
      padding: '0.25rem 0.5rem',
      fontSize: '0.875rem',
      transition: 'background-color 0.2s',
      ':hover': {
        backgroundColor: "#1a2235",
      },
    },
  
    selectedTagStyles: {
      backgroundColor: "#3b82f6",
      color: "white",
    },

    navigationSidebarClasses: "relative h-screen overflow-hidden noise-bg",

    navigationSidebarStyles: {
      background: "linear-gradient(108.74deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 50%)",
      boxShadow: "0px 0px 50px -25px rgba(0, 0, 0, 0.5)",
      backdropFilter: "blur(50px)",
    },
};