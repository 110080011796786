import React, { useState } from 'react';
import { SummarySection } from './SummarySection';  // Adjust the import path as needed
import { sharedStyles } from '../components/sharedStyles';

const BillCard = ({ bill }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div  style={sharedStyles.containerStyles}>
      <div style={sharedStyles.strokeStyles} />
      <div >
        <SummarySection 
          isExpanded={isExpanded}
          onToggle={handleToggle}
          billId={bill.id}
        />
      </div>
    </div>
  );
};

export default BillCard;