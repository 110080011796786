import React from 'react';

const GoogleLoginButton = () => {
  const handleGoogleLogin = () => {
    // Redirect the user to the backend Google OAuth login endpoint
    const backendUrl = process.env.REACT_APP_API_URL;
    
    window.location.href = `${backendUrl}/api/auth/social/google/login/`;
  };

  // Inline styles
  const buttonStyle = {
    backgroundColor: '#131314',
    border: '1px solid #747775',
    borderRadius: '20px',
    color: '#e3e3e3',
    cursor: 'pointer',
    fontFamily: 'Roboto, arial, sans-serif',
    fontSize: '14px',
    height: '40px',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color .218s, border-color .218s, box-shadow .218s',
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)',
    width: 'auto',
    maxWidth: '400px',
    minWidth: 'min-content',
    outline: 'none',
    overflow: 'hidden',
  };

  const iconStyle = {
    height: '20px',
    width: '20px',
    marginRight: '12px',
  };

  const contentWrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  };

  const contentStyle = {
    flexGrow: 1,
    fontFamily: 'Roboto, arial, sans-serif',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top',
  };

  return (
    <button onClick={handleGoogleLogin} style={buttonStyle} className="gsi-material-button">
      <div style={contentWrapperStyle} className="gsi-material-button-content-wrapper">
        <img src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg" alt="Google Logo" style={iconStyle} className="gsi-material-button-icon" />
        <span style={contentStyle} className="gsi-material-button-contents">
          Sign in with Google
        </span>
      </div>
    </button>
  );
};

export default GoogleLoginButton;