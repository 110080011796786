import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';
import { SummarySection } from '../components/SummarySection';
import { QueryInputBox } from '../components/QueryInputBox';
import { ConversationArea } from '../components/ConversationArea';
import RAGChat from '../components/RAGChat';
import { sharedStyles } from '../components/sharedStyles';
import PDFViewer from '../components/PDFView';
import NavigationSidebar from "../components/NavigationSideBar";
import PageContainer from "../components/PageContainer";

export default function Bill() {
    const [isSummaryExpanded, setIsSummaryExpanded] = useState(true);
    const { billId } = useParams();
    const [billData, setBillData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectedCitation, setSelectedCitation] = useState(null);
    const historyLoadedRef = useRef(false);
    const [pdfError, setPdfError] = useState(null);
    const pdfViewerRef = useRef(null);

    const handlePdfError = useCallback((err) => {
        console.log('PDF Error occurred:', err);
        setPdfError(true);
    }, []);

    useEffect(() => {
        const fetchBillSummary = async () => {
            if (billId === null) return;
            try {
                setLoading(true);
                const response = await api.get(`/api/bills/summary/${billId}/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setBillData(response.data);
            } catch (err) {
                setError('Failed to fetch bill summary');
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
    
        fetchBillSummary();
    }, [billId]);
  
    useEffect(() => {
        if (historyLoadedRef.current) return;

        const loadChatHistory = async () => {
            if (!billId) {
                console.error('Bill ID is required to load chat history');
                return;
            }
            try {
                const history = await RAGChat.fetchChatHistory(billId);
                if (history.length === 0) {
                    // No existing chat, start a new one
                    console.log("No chat history found, starting new chat");
                    const newHistory = await RAGChat.startNewChat(billId);
                    setMessages(newHistory);
                } else {
                    console.log("Chat history found, loading messages");
                    setMessages(history);
                }
                historyLoadedRef.current = true;
            } catch (error) {
                console.error('Error loading chat history:', error);
            }
        };

        loadChatHistory();
    }, [billId]);
  
    const toggleSummary = () => {
        setIsSummaryExpanded(!isSummaryExpanded);
    };
  
    const handleSubmit = async (query) => {
        if (!billId || pdfError) {
            console.error('Cannot submit query: Bill ID is missing or PDF is unavailable');
            return;
        }
        setMessages(prevMessages => [...prevMessages, { type: 'user', content: query }]);
        try {
            const response = await RAGChat.sendMessage(billId, query);
            setMessages(prevMessages => [...prevMessages, { type: 'assistant', content: response.answer.answer, citations: response.answer.citations }]);
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prevMessages => [...prevMessages, { type: 'assistant', content: 'Sorry, an error occurred. Please try again.' }]);
        }
    };

    const handleCitationClick = (citation) => {
        console.log('Citation clicked:', citation); // Add this line
        setSelectedCitation(citation);

        if (pdfViewerRef.current) {
            pdfViewerRef.current.highlightCitation(citation);
        }
    };

    if (billId === null) {
        return <div>Loading...</div>;
    }
  
    return (
        <PageContainer>
        <div className="flex h-screen overflow-hidden bg-[#151c2c] text-white">
            <NavigationSidebar />
            <div className="flex h-screen bg-gray-900 p-4">
                <div className="flex flex-col w-1/2 gap-2">
                    <div className={`flex-grow flex flex-col overflow-hidden ${sharedStyles.containerClasses}`} style={sharedStyles.containerStyles}>
                        <div style={sharedStyles.strokeStyles} />
                        <div className="flex-grow flex flex-col overflow-hidden p-4">
                            <div className="flex-shrink-0">
                                {loading ? (
                                    <div className="text-white">Loading...</div>
                                ) : error ? (
                                    <div className="text-white">{error}</div>
                                ) : (
                                    <SummarySection
                                        billId={billId}
                                        isExpanded={isSummaryExpanded}
                                        onToggle={toggleSummary}
                                    />
                                )}
                            </div>
                            <div className="flex-grow overflow-y-auto mt-4">
                                <ConversationArea messages={messages} onCitationClick={handleCitationClick} />
                            </div>
                        </div>
                    </div>
                    <div className="flex-shrink-0">
                        <QueryInputBox 
                            onSubmit={handleSubmit}
                            disabled={pdfError}
                            placeholder={pdfError ? "Chat will be enabled when PDF becomes available.": "Ask or search..." } 
                        />
                    </div>
                </div>
                <div className="w-1/2 pl-2 h-full">
                    <div className="w-full h-full rounded-md overflow-hidden" style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    }}>
                        {pdfError ? (
                            <div className='flex items-center justify-center h-full text-black'>
                                PDF will appear when it becomes available.
                            </div>
                        ) : (
                            <PDFViewer 
                            ref={pdfViewerRef}
                            billId={billId} 
                            onError={handlePdfError} 
                             />
                        )}
                    </div>
                </div>
            </div>
        </div>
     </PageContainer>
    );
}
