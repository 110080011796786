import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { sharedStyles } from './sharedStyles';

export function QueryInputBox({ onSubmit, placeholder = "Ask or search", disabled }) {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query.trim()) {
      onSubmit(query);
      setQuery('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={`w-full ${sharedStyles.containerClasses}`} style={sharedStyles.containerStyles}>
      <div style={sharedStyles.strokeStyles} />
      <div className="relative z-10 w-full h-full flex items-center px-4 py-2">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          className="flex-1 bg-transparent text-white placeholder-white/50 focus:outline-none"
          style={sharedStyles.inputStyles}
        />
        <button type="submit" className="ml-2 text-white flex-shrink-0" disabled={disabled}>
          <ArrowRight size={20} />
        </button>
      </div>
    </form>
  );
}