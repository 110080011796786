import React from 'react';
import { Search } from 'lucide-react';

const SearchBar = ({ placeholder, value, onChange, onEnterPress }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnterPress();
    }
  };

  return (
    <div className="relative mb-8">
      <input
        type="text"
        placeholder={placeholder}
        className="w-full py-3 px-4 pl-10 bg-[#1e2738] rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}

      />
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
    </div>
  );
};

export default SearchBar;