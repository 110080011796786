import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import api from '../api';
import { sharedStyles } from '../components/sharedStyles';

const TagsMenu = ({ selectedTags, onTagSelect }) => {
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const response = await api.get('/api/tags/'); // Assuming you have an endpoint to fetch all tags
      setTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const filteredTags = tags.filter(tag => 
    tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <aside className="w-64 p-4 overflow-y-auto flex flex-col" style={sharedStyles.tagsMenuStyles}>
    <div style={sharedStyles.strokeStyles} />
    <div className="relative mb-4">
      <input
        type="text"
        placeholder="Search Tags"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full py-1 px-3 pl-10 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        style={sharedStyles.tagsMenuInputStyles}
      />
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
    </div>
    <h3 className="text-lg font-semibold mb-2">Tags</h3>
    <div className="flex flex-wrap gap-2">
      {filteredTags.map(tag => (
        <button
          key={tag}
          onClick={() => onTagSelect(tag)}
          style={{
            ...sharedStyles.tagStyles,
            ...(selectedTags.includes(tag) ? sharedStyles.selectedTagStyles : {})
          }}
        >
          {tag}
        </button>
      ))}
    </div>
  </aside>
);
};

export default TagsMenu;