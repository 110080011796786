import React from 'react';

function PageContainer({ children }) {
  return (
    <div className="flex h-screen p-4 overflow-hidden bg-[#151c2c] text-white">
    {children}
    </div>
  );
}

export default PageContainer;