// src/api.js
import axios from "axios";
// import { ACCESS_TOKEN, REFRESH_TOKEN } from "./constants";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "",
});

// Define routes that require authentication
const authRequiredRoutes = [
    '/api/user-chat-sessions/',
    '/api/rag',  // Add more routes as needed
];

// Attach the access token conditionally to outgoing requests
api.interceptors.request.use((config) => {
    // Only attach the token for routes that require authentication
    if (authRequiredRoutes.some(route => config.url.startsWith(route))) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;